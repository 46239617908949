<template>
  <div class="pcpay">
    <pay :courseVO="courseVO" :qrUrl="codeUrl" v-on:on-pay="onPay" :payReady="payReady"></pay>
    <csWidget v-if="showCsWidget" :csId="'1'"></csWidget>
  </div>
</template>

<script>
const QUERY_INTERVAL = 5000;

import pay from "@/components/pay.vue";
import csWidget from "@/components/csWidget.vue";
import { Notify } from "vant";
import { payApi } from "@/api/payApi.js";
import { orderApi } from "@/api/orderApi.js";
import { courseApi } from "@/api/courseApi.js";

export default {
  components: { pay, csWidget },
  data() {
    return {
      courseId: 0,
      orderId: 0,
      courseInfo: {},
      codeUrl: "",
      courseVO: {
        course: {
          scheduleCnt: 0,
          saleCount: 0,
          showPrice: 0,
          truePrice: 0,
          tags: [],
        },
        scheduleList: [],
        ownThisCourse: 0,
        teacherList: [],
      },
      clock: null,
      showCsWidget: false,
      csTitle: "参数出错，请联系客服",
      amount: 0,
      payReady: true,
    };
  },
  methods: {
    onPay(orderDiscountVO) {
      console.log("..active on pay", orderDiscountVO);
      orderApi
        .create(this.courseId, orderDiscountVO.referPhone, orderDiscountVO.reducePriceLong)
        .then((ret) => {
          const { code, msg, data } = ret;
          if (code !== 0) {
            this.csTitle = msg;
            this.showCsWidget = true;
            return;
          }
          this.orderId = data.id;
          const _this = this;
          this.amount = this.courseInfo.truePrice;
          if (orderDiscountVO && orderDiscountVO.totalFee > 0) {
            this.amount = orderDiscountVO.totalFee;
          }
          console.log("amount", this.amount);
          payApi
            .startPayWithoutOpenId("WXPAY_NATIVE", this.orderId, this.amount)
            .then((ret) => {
              if (ret.code == 0 && ret.data) {
                this.codeUrl = ret.data.codeUrl;
                this.clock = setInterval(() => {
                  this.onQueryPayment();
                }, QUERY_INTERVAL);
              }
            })
            .catch((err) => {
              this.showCsWidget = true;
            });
        })
        .catch((err) => {
          this.showCsWidget = true;
        });
    },
    getId() {
      if (this.$route.query && this.$route.query.id) {
        this.courseId = this.$route.query.id;
      }
      if (this.courseId <= 0) {
        this.showCsWidget = true;
      }
      return this.courseId;
    },
    getCourse(courseId) {
      courseApi.courseInfo(courseId).then((ret) => {
        if (ret && ret.code == 0 && ret.data) {
          this.courseInfo = ret.data.course;
          this.courseVO = ret.data;
          console.log("courseVO...", this.courseVO);
        } else {
          this.showCsWidget = true;
        }
      });
    },
    onQueryPayment() {
      if (this.orderId <= 0) {
        console.log("order id <= 0", this.orderId);
        return;
      }
      orderApi
        .queryOrderPayment(this.orderId)
        .then((ret) => {
          const { code, msg, data } = ret;
          console.log("..", data);
          if (code === 0 && data) {
            if (data.status === 1) {
              // 已支付
              clearInterval(this.clock);
              this.$router.push({
                path: "/pay/payWaiting",
                query: {
                  id: this.orderId,
                },
              });
            }
          } else {
            this.showCsWidget = true;
          }
        })
        .catch((err) => {
          this.showCsWidget = true;
        });
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
  },
  mounted() {
    const courseId = this.getId();
    this.courseId = courseId;
    this.getCourse(courseId);
    this.listenOnCloseCsWidget();
    this.$once("hook:beforeDestory", function() {
      clearInterval(this.clock);
      this.clock = null;
    });
  },
  beforeDestroy() {
    clearInterval(this.clock);
    this.clock = null;
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.pcpay {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
}
</style>
